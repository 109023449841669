import { makeConstant } from '~/helper/typescript';

import type { stringOrNumber } from 'types';

export default makeConstant({
  AddTopicTemplateToGroupsModalSaveToGroup: 'add-topic-template-to-groups-modal-save-to-group',
  AddTopicTemplateModalPostToNewGroup: 'add-topic-template-modal-post-to-new-group',
  FeedbackModalFeedbackTypeSelection: 'Feedback-Type-Select-Option',
  MobilePromptClosePrompt: 'MobilePrompt-Close-Prompt',
  MobilePromptDownloadApp: 'UseMobileModal-Download-App',
  MobilePromptOpenStore: 'MobilePrompt-Open-Store',
  MobilePromptOpenApp: 'UseMobileModal-Open-App',
  SubmitFeedbackModalDone: 'Submit-Feedback-Modal-Done',
  TopicEmptyStateExploreDiscoveryCard: 'Topic-Empty-State-Explore-Discovery-Card',
  TopicEmptyStateExploreDiscoveryButton: 'Topic-Empty-State-Explore-Discovery-Button',
  TopicEmptyStateAddTopicCard: (index: stringOrNumber) => `Topic-Empty-State-Add-Topic-Card-${index}` as const,
  TopicEmptyStateAddTopicButton: (index: stringOrNumber) => `Topic-Empty-State-Add-Topic-Button-${index}` as const,
  TopicTemplateModalAdd: 'Topic-Template-Modal-Add',
  TopicTemplateModalAddToNewGroup: 'Topic-Template-Modal-Add-To-New-Group',
  TopicTemplateModalAddTopic: 'Topic-Template-Modal-Add-Topic',
  TopicTemplateModalShare: 'Topic-Template-Modal-Share',
});
